import { useEffect, useState } from "react";
import styles from "./Workshop.module.css";
import { API_HOST_URL, api } from "../../config/apiConfig";
import workshopsImage from "./images/workshops.jpg";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarSharp from "@mui/icons-material/StarSharp";
import WorkshopDetail from "./WorkshopDetail";
import mainStyles from "../Main.module.css";
import AppPagination from "../Pagination/AppPagination";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

export default function WorkshopList() {
  const [workshops, setWorkshops] = useState([]);
  const [showWorkshopDetail, setShowWorkshopDetail] = useState(false);
  const [showWorkshopContainer, setShowWorkshopContainer] = useState(false);
  const [showWorkshopList, setShowWorkshopList] = useState(true);
  const [workshopId, setWorkshopId] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  let role = localStorage.getItem("role");

  useEffect(() => {
    api
      .get("/api/workshops?page=" + page)

      .then((res) => {
        setWorkshops(res.data.workshops);
        setTotalPages(res.data.totalPages);

        if (res.data.totalPages > 0) {
          setShowWorkshopContainer(true);
        }
      });
  }, [page]);

  const handleViewMore = (e, workshop) => {
    navigate("/workshopDetail?workshopId=" + workshop);
  };

  const handleUpdate = (e, workshop) => {
    navigate("/updateWorkshop?workshopId=" + workshop);
  };

  return (
    <div>
      <div>
        <div className={styles.headingImageDiv}>
          <img src={workshopsImage} alt="Bikes" className={styles.bigImage} />
        </div>

        {showWorkshopContainer && (
          <div className={styles.workshopListcontainer}>
            <header className={mainStyles.header}>Workshops</header>
            {workshops.map((workshop) => (
              <div className={styles.propContainer}>
                <div className={styles.workshopDetailContainer}>
                  <div className={styles.workshopImageDiv}>
                    <img
                      onClick={(e) => handleViewMore(e, workshop.workshopId)}
                      className={styles.workshopImage}
                      src={workshop.imageUrl}
                      alt="workshopImage"
                    />
                  </div>
                  <div className={styles.workshopDetailDiv}>
                    <div className={styles.workshopDetail}>
                      <div className={styles.name}>{workshop.name}</div>
                      <div className={styles.starContainer}>
                        {[...Array(workshop.rating)].map((item, i) => (
                          <div className={styles.starActive} key={i}>
                            <StarSharp />
                          </div>
                        ))}
                      </div>
                      <div className={styles.yearsOld}>
                        {workshop.yearsOld} year(s) old
                      </div>

                      <div>
                        <div className={styles.locationIcon}>
                          <LocationOnIcon fontSize="medium" />{" "}
                          {workshop.address}, {workshop.city}
                        </div>
                      </div>

                      <Button
                        onClick={(e) => handleViewMore(e, workshop.workshopId)}
                        className={mainStyles.button}
                      >
                        View
                      </Button>
                      {role === "ADMIN" && (
                        <>
                          {" "}
                          <Button
                            onClick={(e) =>
                              handleUpdate(e, workshop.workshopId)
                            }
                            className={`${mainStyles.button} ${mainStyles.delete}`}
                          >
                            Update
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <AppPagination totalPages={totalPages} setPage={setPage} />
          </div>
        )}
      </div>
    </div>
  );
}
