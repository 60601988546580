import { useState, useEffect } from "react";
import axios from "axios";
import { TextField, Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import styles from "./UsedBike.module.css";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { API_HOST_URL } from "../Constant/Constant";
import mainStyles from "../Main.module.css";
import loadingImage from "../loading.gif";
import validator from "validator";
import { api } from "../../config/apiConfig";

export default function UsedBike() {
  const [colors, setColors] = useState([]);
  const [brands, setBrands] = useState([]);
  const [cities, setCities] = useState([]);
  const [models, setModels] = useState([]);
  const [bikeModelId, setBikeModelId] = useState();
  const [purchaseYear, setPurchaseYear] = useState();
  const [brandId, setBrandId] = useState();
  const [color, setColor] = useState();
  const [runningKms, setRunningKms] = useState();
  const [sellerName, setSellerName] = useState();
  const [sellerMobile, setSellerMobile] = useState();
  const [sellerAddress, setSellerAddress] = useState();
  const [sellerCityId, setSellerCityId] = useState();
  const [price, setPrice] = useState();
  const [registrationNumber, setRegistrationNumber] = useState();
  const [description, setDescription] = useState();
  const [isSaved, setIsSaved] = useState(false);
  const [bikeId, setBikeId] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [topViewImage, setTopViewImage] = useState();
  const [leftViewImage, setLeftViewImage] = useState();
  const [rightViewImage, setRightViewImage] = useState();
  const [frontViewImage, setFrontViewImage] = useState();
  const [backViewImage, setBackViewImage] = useState();
  const [topViewImageUploaded, setTopViewImageUploaded] = useState(false);
  const [leftViewImageUploaded, setLeftViewImageUploaded] = useState(false);
  const [rightViewImageUploaded, setRightViewImageUploaded] = useState(false);
  const [frontViewImageUploaded, setFrontViewImageUploaded] = useState(false);
  const [backViewImageUploaded, setBackViewImageUploaded] = useState(false);

  const [topViewImageUploadFail, setTopViewImageUploadFail] = useState(false);
  const [leftViewImageUploadFail, setLeftViewImageUploadFail] = useState(false);
  const [rightViewImageUploadFail, setRightViewImageUploadFail] =
    useState(false);
  const [frontViewImageUploadFail, setFrontViewImageUploadFail] =
    useState(false);
  const [backViewImageUploadFail, setBackViewImageUploadFail] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [frontImageLoading, setFrontImageLoading] = useState(false);
  const [topImageLoading, setTopImageLoading] = useState(false);
  const [leftImageLoading, setLeftImageLoading] = useState(false);
  const [rightImageLoading, setRightImageLoading] = useState(false);
  const [backImageLoading, setBackImageLoading] = useState(false);

  const fetchInitialData = () => {
    const getAllColors = api.get("/colors/");
    const getAllBrands = api.get("/api/brands/");
    const getAllCities = api.get("/cities/");

    axios.all([getAllColors, getAllBrands, getAllCities]).then(
      axios.spread((...allData) => {
        setColors(allData[0].data);
        setBrands(allData[1].data);
        setCities(allData[2].data);
      })
    );
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const loadModels = (e, brand) => {
    api.get("/api/bikeModels/" + brand).then((res) => {
      setBrandId(brand);
      setModels(res.data);
    });
  };

  const handleSubmitTop = (e) => {
    e.preventDefault();
    setTopImageLoading(true);
    setTopViewImageUploaded(false);
    setTopViewImageUploadFail(false);

    const formData = new FormData();
    formData.append("file", topViewImage);
    formData.append("imageAngle", "TOP");
    saveImage(formData);
  };

  const handleSubmitFront = (e) => {
    e.preventDefault();

    setFrontImageLoading(true);
    setFrontViewImageUploaded(false);
    setFrontViewImageUploadFail(false);

    const formData = new FormData();
    formData.append("file", frontViewImage);
    formData.append("imageAngle", "FRONT");
    saveImage(formData);
  };

  const handleSubmitLeft = (e) => {
    e.preventDefault();
    setLeftImageLoading(true);

    setLeftViewImageUploaded(false);
    setLeftViewImageUploadFail(false);

    const formData = new FormData();
    formData.append("file", leftViewImage);
    formData.append("imageAngle", "LEFT");
    saveImage(formData);
  };

  const handleSubmitRight = (e) => {
    e.preventDefault();
    setRightImageLoading(true);

    setRightViewImageUploaded(false);
    setRightViewImageUploadFail(false);

    const formData = new FormData();
    formData.append("file", rightViewImage);
    formData.append("imageAngle", "RIGHT");
    saveImage(formData);
  };

  const handleSubmitBack = (e) => {
    e.preventDefault();
    setBackImageLoading(true);

    setBackViewImageUploaded(false);
    setBackViewImageUploadFail(false);

    const formData = new FormData();
    formData.append("file", backViewImage);
    formData.append("imageAngle", "BACK");
    saveImage(formData);
  };

  const saveImage = (params) => {
    params.append("bikeId", bikeId);
    var saved = true;

    api
      .post("/api/usedBikes/images/", params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setBikeId(res.data.bikeId);
        updateImage(res.data);
        setImageLoadingToFalse();
        setImageUploadedToTrue(params.get("imageAngle"));
        saved = true;
      })
      .catch((error) => {
        console.log(error);
        saved = false;
        setImageLoadingToFalse();
        setImageUploadedToFalse(params.get("imageAngle"));
      });
    return saved;
  };

  const setImageUploadedToTrue = (angle) => {
    if (angle === "TOP") {
      setTopViewImageUploaded(true);
    } else if (angle === "FRONT") {
      setFrontViewImageUploaded(true);
    } else if (angle === "LEFT") {
      setLeftViewImageUploaded(true);
    } else if (angle === "RIGHT") {
      setRightViewImageUploaded(true);
    } else if (angle === "BACK") {
      setBackViewImageUploaded(true);
    }
  };

  const setImageUploadedToFalse = (angle) => {
    if (angle === "TOP") {
      setTopViewImageUploadFail(true);
    } else if (angle === "FRONT") {
      setFrontViewImageUploadFail(true);
    } else if (angle === "LEFT") {
      setLeftViewImageUploadFail(true);
    } else if (angle === "RIGHT") {
      setRightViewImageUploadFail(true);
    } else if (angle === "BACK") {
      setBackViewImageUploadFail(true);
    }
  };

  const setImageLoadingToFalse = () => {
    setFrontImageLoading(false);
    setTopImageLoading(false);
    setLeftImageLoading(false);
    setRightImageLoading(false);
    setBackImageLoading(false);
  };

  const updateImage = (res) => {
    if (res.imageAngle === "TOP") {
      setTopViewImage(res.imageUrl);
    } else if (res.imageAngle === "FRONT") {
      setFrontViewImage(res.imageUrl);
    } else if (res.imageAngle === "LEFT") {
      setLeftViewImage(res.imageUrl);
    } else if (res.imageAngle === "RIGHT") {
      setRightViewImage(res.imageUrl);
    } else if (res.imageAngle === "BACK") {
      setBackViewImage(res.imageUrl);
    }
  };

  const years = [
    2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011,
    2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023,
    2024,
  ];

  const validatePhoneNumber = (number) => {
    const isValidPhoneNumber = validator.isMobilePhone(number);
    setSellerMobile(number);
    setIsValidPhoneNumber(isValidPhoneNumber);
  };

  const isValidForm = () => {
    setErrorMessage();
    if (bikeId === undefined || bikeId === "") {
      setErrorMessage("Bike Front image is required");
      return false;
    } else if (brandId === undefined || brandId === "") {
      setErrorMessage("Brand is required");
      return false;
    } else if (bikeModelId === undefined || bikeModelId === "") {
      setErrorMessage("Bike Model is required");
      return false;
    } else if (color === undefined || color === "") {
      setErrorMessage("Color is required");
      return false;
    } else if (purchaseYear === undefined || purchaseYear === "") {
      setErrorMessage("Purchase Year is required");
      return false;
    } else if (sellerName === undefined || sellerName === "") {
      setErrorMessage("Seller Name is required");
      return false;
    } else if (sellerMobile === undefined || sellerMobile === "") {
      setErrorMessage("Seller Mobile is required");
      return false;
    } else if (sellerAddress === undefined || sellerAddress === "") {
      setErrorMessage("Seller Address is required");
      return false;
    } else if (sellerCityId === undefined || sellerCityId === "") {
      setErrorMessage("Seller City is required");
      return false;
    } else if (price === undefined || price === "") {
      setErrorMessage("Price is required");
      return false;
    } else if (!frontViewImageUploaded) {
      setErrorMessage("Front View Image is required");
      return false;
    } else if (!isValidPhoneNumber) {
      setErrorMessage("Phone number is invalid");
      return false;
    }
    return true;
  };

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const resetForm = () => {
    setBikeId("");
    setBikeModelId();
    setBrandId();
    setColor();
    setPurchaseYear();
    setRunningKms();
    setSellerName();
    setSellerMobile();
    setSellerAddress();
    setSellerCityId();
    setPrice();
    setRegistrationNumber();
    setDescription();
    setTopViewImage();
    setLeftViewImage();
    setRightViewImage();
    setFrontViewImage();
    setBackViewImage();
    setTopViewImageUploaded(false);
    setLeftViewImageUploaded(false);
    setRightViewImageUploaded(false);
    setFrontViewImageUploaded(false);
    setBackViewImageUploaded(false);
    setTopViewImageUploadFail(false);
    setLeftViewImageUploadFail(false);
    setRightViewImageUploadFail(false);
    setFrontViewImageUploadFail(false);
    setBackViewImageUploadFail(false);
    setIsValidPhoneNumber(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    var customerId = localStorage.getItem("userId");
    const usedBike = {
      customerId,
      brandId,
      bikeModelId,
      color,
      description,
      purchaseYear,
      runningKms,
      sellerName,
      sellerMobile,
      sellerAddress,
      sellerCityId,
      price,
      registrationNumber,
      bikeId,
    };

    if (isValidForm()) {
      api
        .post("/api/usedBikes/", usedBike)
        .then((res) => {
          onSuccessfulSave();
          resetForm();
        })
        .catch((error) => {
          onFailedSave();
        });
    } else {
      onFailedSave();
    }
  };

  return isLoading ? (
    <div className={mainStyles.loading}>
      <img src={loadingImage} alt="loading" />
    </div>
  ) : (
    <div>
      <header className={mainStyles.header}>Used Bike Registration</header>

      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onChange={(e) => setSellerName(e.target.value)}
          label="Enter Seller Name"
        />
      </div>

      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          type="phone"
          variant="standard"
          onChange={(e) => validatePhoneNumber(e.target.value)}
          label="Enter Seller Mobile"
        />
      </div>

      <div className={styles.componentContainer}>
        <TextField
          className={`${styles.component}`}
          onChange={(e) => setSellerAddress(e.target.value)}
          label="Enter Address"
          multiline
          rows={3}
        />
      </div>

      <div className={styles.ddlContainer}>
        <select
          className={`${styles.ddl}`}
          onChange={(e) => setSellerCityId(e.target.value)}
        >
          <option> Select City </option>
          {cities.map((city) => (
            <option key={city.cityId} value={city.cityId}>
              {city.cityName}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.ddlContainer}>
        <select
          className={`${styles.ddl}`}
          onChange={(e) => loadModels(e, e.target.value)}
        >
          <option> Select Brand </option>
          {brands.map((brand) => (
            <option key={brand.brandId} value={brand.brandId}>
              {brand.brandName}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.ddlContainer}>
        <select
          className={`${styles.ddl}`}
          onChange={(e) => setBikeModelId(e.target.value)}
        >
          <option> Select Model </option>
          {models.map((model) => (
            <option key={model.modelId} value={model.modelId}>
              {model.name}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.ddlContainer}>
        <select
          className={`${styles.ddl}`}
          onChange={(e) => setColor(e.target.value)}
        >
          <option> Select Color </option>
          {colors.map((color) => (
            <option key={color} value={color}>
              {color}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onChange={(e) => setRegistrationNumber(e.target.value)}
          label="Enter Registration Number"
        />
      </div>

      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          type="number"
          variant="standard"
          onChange={(e) => setRunningKms(e.target.value)}
          label="Enter Running KMs"
        />
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          type="number"
          variant="standard"
          onChange={(e) => setPrice(e.target.value)}
          label="Enter Selling Price"
        />
      </div>

      <div className={styles.ddlContainer}>
        <select
          className={`${styles.ddl}`}
          onChange={(e) => setPurchaseYear(e.target.value)}
        >
          <option> Select Purchase Year </option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={`${styles.component}`}
          onChange={(e) => setDescription(e.target.value)}
          label="Enter remarks"
          multiline
          rows={3}
        />
      </div>

      <Divider>
        <Chip label="Image for Bike Front view" size="small" />
      </Divider>

      <div className={styles.uploadFormContainer}>
        {frontViewImage && (
          <img
            className={styles.updateImage}
            src={frontViewImage}
            alt="front_image"
          />
        )}
        {frontImageLoading && (
          <div>
            <img
              src={loadingImage}
              className={mainStyles.smallLoadingImage}
              alt="loading"
            />
          </div>
        )}
        <form onSubmit={handleSubmitFront} encType="multipart/form-data">
          <input
            type="file"
            onChange={(e) => setFrontViewImage(e.target.files[0])}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {frontViewImageUploaded && (
            <p className={mainStyles.successMessage}>
              {" "}
              Image uploaded Successfully
            </p>
          )}
          {frontViewImageUploadFail && (
            <p className={mainStyles.errorMessage}> Image upload Failed</p>
          )}
        </form>
      </div>
      <Divider>
        <Chip label="Image for Bike Top view" size="small" />
      </Divider>
      <div className={styles.uploadFormContainer}>
        {topViewImage && (
          <img
            className={styles.updateImage}
            src={topViewImage}
            alt="top_image"
          />
        )}
        <form onSubmit={handleSubmitTop} encType="multipart/form-data">
          {topImageLoading && (
            <div>
              <img
                src={loadingImage}
                className={mainStyles.smallLoadingImage}
                alt="loading"
              />
            </div>
          )}
          <input
            type="file"
            onChange={(e) => setTopViewImage(e.target.files[0])}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {topViewImageUploaded && (
            <p className={mainStyles.successMessage}>
              {" "}
              Image uploaded Successfully
            </p>
          )}
          {topViewImageUploadFail && (
            <p className={mainStyles.errorMessage}> Image upload Failed</p>
          )}
        </form>
      </div>

      <Divider>
        <Chip label="Image for Bike Left view" size="small" />
      </Divider>
      <div className={styles.uploadFormContainer}>
        {leftViewImage && (
          <img
            className={styles.updateImage}
            src={leftViewImage}
            alt="left_image"
          />
        )}
        <form onSubmit={handleSubmitLeft} encType="multipart/form-data">
          {leftImageLoading && (
            <div>
              <img
                src={loadingImage}
                className={mainStyles.smallLoadingImage}
                alt="loading"
              />
            </div>
          )}
          <input
            type="file"
            onChange={(e) => setLeftViewImage(e.target.files[0])}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {leftViewImageUploaded && (
            <p className={mainStyles.successMessage}>
              {" "}
              Image uploaded Successfully
            </p>
          )}
          {leftViewImageUploadFail && (
            <p className={mainStyles.errorMessage}> Image upload Failed</p>
          )}
        </form>
      </div>

      <Divider>
        <Chip label="Image for Bike Right view" size="small" />
      </Divider>
      <div className={styles.uploadFormContainer}>
        {rightViewImage && (
          <img
            className={styles.updateImage}
            src={rightViewImage}
            alt="right_image"
          />
        )}
        <form onSubmit={handleSubmitRight} encType="multipart/form-data">
          {rightImageLoading && (
            <div>
              <img
                src={loadingImage}
                className={mainStyles.smallLoadingImage}
                alt="loading"
              />
            </div>
          )}
          <input
            type="file"
            onChange={(e) => setRightViewImage(e.target.files[0])}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {rightViewImageUploaded && (
            <p className={mainStyles.successMessage}>
              {" "}
              Image uploaded Successfully
            </p>
          )}
          {rightViewImageUploadFail && (
            <p className={mainStyles.errorMessage}> Image upload Failed</p>
          )}
        </form>
      </div>

      <Divider>
        <Chip label="Image for Bike Back view" size="small" />
      </Divider>

      <div className={styles.uploadFormContainer}>
        {backViewImage && (
          <img
            className={styles.updateImage}
            src={backViewImage}
            alt="back_image"
          />
        )}
        <form onSubmit={handleSubmitBack} encType="multipart/form-data">
          {backImageLoading && (
            <div>
              <img
                src={loadingImage}
                className={mainStyles.smallLoadingImage}
                alt="loading"
              />
            </div>
          )}
          <input
            type="file"
            onChange={(e) => setBackViewImage(e.target.files[0])}
          />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {backViewImageUploaded && (
            <p className={mainStyles.successMessage}>
              {" "}
              Image uploaded Successfully
            </p>
          )}
          {backViewImageUploadFail && (
            <p className={mainStyles.errorMessage}> Image upload Failed</p>
          )}
        </form>
      </div>

      <Divider></Divider>

      <div className={styles.componentContainer}>
        <Button
          onClick={handleSubmit}
          className={`${styles.component}`}
          type="submit"
          variant="contained"
          startIcon={<SaveIcon />}
        >
          {" "}
          Save Bike
        </Button>
      </div>

      <div className={styles.componentContainer}>
        {isSaved && (
          <div>
            <p className={mainStyles.success}>Bike Saved Successfully</p>
          </div>
        )}
        {errorMessage && <p className={mainStyles.error}>{errorMessage}</p>}
      </div>
    </div>
  );
}
